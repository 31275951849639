<template lang="pug">
div.text-center
  slot(:save="save" :input="input" :noteText="noteText" :inputChanged="inputChanged" :updateInput="updateInput" :startEditing="startEditing")
    v-menu(v-model="editMode"   :close-on-content-click="false")
      template(v-slot:activator="{ on }")
          //- v-btn(outlined x-small @click="startEditing" v-show="note === '-'" v-on="on").btn-common create
          p(data-test="note-text" @click="startEditing" v-on="on" :class="{'note_create': ['Add note', buttonPlaceholder].includes(noteText)}").note {{ noteText }}

      v-card
        v-card-text
          v-textarea(v-model="input" data-test="note-input" ref="input" hide-details :placeholder="textPlaceholder")
        
        v-card-actions
          v-spacer
          v-btn(text @click="cancelEditing" color="primary" small) cancel
          v-btn(color="primary" data-test="note-save" @click="save" :disabled="!inputChanged" small) save
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
export default {
  mixins: [errorsMixin],
  props: {
    value: {
      required: true
    },
    ID: {
      required: true,
      type: Number
    },
    buttonPlaceholder: {
      type: String,
      default: '-'
    },
    textPlaceholder: {
      type: String,
      default: 'Candidate note...'
    },
    service: {
      type: Object,
      default: null
    }
  },
  
  inject: ['svc'],

  data: () => ({
    editMode: false,
    input: null
  }),

  computed: {
    note() {
      if(!this.value) return this.buttonPlaceholder;
      return this.value
    },

    noteText() {
      if(this.note !== '-') return this.note
      return 'Add note'
    },

    inputChanged() {
      if(this.input === this.value) return false
      return true
    }
  },

  methods: {
    startEditing() {
      this.editMode = true
      this.input = this.value
    },

    cancelEditing() {
      this.editMode = false
      this.input = this.value
    },


    async save() {
      try {
        await this.Svc().saveNote(this.ID, this.input)
        this.editMode = false
        this.$emit('saved', this.input)
      } catch (error) {
        console.log(error)
        this.showing = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    Svc() {
      return this.service ? this.service : this.svc()
    },

    updateInput(val) {
      this.input = val
    }
  },

  watch: {
    async editMode(isEditMode) {
      if(isEditMode) {
        await this.$nextTick()
        setTimeout(() => {
          this.$refs.input.focus()
        }, 100);

      } 
    }
  }
}
</script>

<style lang="scss" scoped>
.note {
  overflow: hidden;
  margin-bottom: 0;
  max-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &_create {
    display: inline-block;
    padding-right: 15px;
    padding-left: 15px;
    border: 1px solid $primary-color;
    border-radius: 6px;
    color: $primary-color;
    cursor: pointer;
  }
}

</style>